<template>
  <ContractAddress v-if="isContract" />
  <AccountAddress v-else />
</template>

<script>
import { Contract } from '@/api';
import ContractAddress from './components/Contract';
import AccountAddress from './components/Account';

export default {
  name: 'Address',
  components: { ContractAddress, AccountAddress },
  data: () => ({
    isContract: false,
  }),
  mounted() {
    this.getContract();
  },
  methods: {
    getContract() {
      Contract.getContract(this.$route.params.hash).then((res) => {
        this.isContract = res.is_contract;
      });
    },
  },
  watch: {
    $route(to, from) {
      this.getContract();
    },
  },
};
</script>
