<template>
  <v-container>
    <v-card elevation="2">
      <v-card-title>
        <span>合约地址：{{ $route.params.hash }}</span>
        <v-chip class="chip" :color="paused ? 'red' : 'green'" outlined>
          {{ paused ? '冻结' : '正常' }}
        </v-chip>
      </v-card-title>
    </v-card>
    <v-card elevation="2" class="card">
      <div class="box">
        <v-row v-for="(_, key) in info" :key="key">
          <v-col cols="4">{{ _[0] }}</v-col>
          <v-col cols="8">
            <RippleLink v-if="key === 'deployer'" :to="`/address/${_[1]}`">
              {{ _[1] }}
            </RippleLink>
            <span v-else-if="key === 'deployed_at'">{{YMDHms(_[1])}}</span>
            <RippleLink
              v-else-if="key === 'deployed_tx_hash'"
              :to="`/transactions/${_[1]}`"
            >
              {{ _[1] }}
            </RippleLink>
            <RippleLink
              v-else-if="key === 'transaction_count'"
              :to="`/transactions/${_[1]}`"
            >
              {{ _[1] }}
            </RippleLink>
            <span v-else>{{ _[1] }}</span>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <Transactions v-if="showTransaction" />
  </v-container>
</template>

<script>
import { RippleLink } from '@/components';
import { Contract, Transaction } from '@/api';
import { YMDHms, YMD, isAssetContract } from '@/utils';
import Transactions from './Transactions';

export default {
  name: 'Contract',
  components: { RippleLink, Transactions },
  data: () => ({
    showTransaction: false,
    paused: false,
    info: {},
    BUSINESS_CONTRACT: {
      deployed_at: ['部署时间', '-'],
      deployer: ['部署者', '-'],
      deployed_tx_hash: ['部署交易哈希', '-'],
      contract_type: ['合约类型', '-'],
      transaction_count: ['关联交易', '-'],
    },
    ASSET_CONTRACT: {
      deployed_at: ['部署时间', '-'],
      deployer: ['部署者', '-'],
      deployed_tx_hash: ['部署交易哈希', '-'],
      contract_type: ['合约类型', '-'],
      contract_type: ['资产类型', '-'],
      contract_symbol: ['资产名称', '-'],
      contract_name: ['资产品牌', '-'],
      expired: ['资产有效期', '-'],
      total_supply_to_decimal: ['发行数量', '-'],
      transaction_count: ['交易区块', '-'],
    },
  }),
  mounted() {
    this.getContract();
  },
  methods: {
    YMDHms,
    YMD,
    getContract() {
      Contract.getContract(this.$route.params.hash).then((res) => {
        const contractType = res.contract.contract_type;
        const isAsset = isAssetContract(contractType);
        this.showTransaction = isAsset;
        this.paused = res.paused;
        const tmp = isAsset
          ? { ...this.ASSET_CONTRACT }
          : { ...this.BUSINESS_CONTRACT };
        Object.keys(tmp).forEach((key) => {
          tmp[key][1] = res.contract[key] || '-';
        });
        tmp.contract_type[1] = isAsset ? '资产合约' : '业务合约';
        if (isAsset) {
          tmp.total_supply_to_decimal[1] = res.total_supply_to_decimal;
        }
        if (contractType === 'COUPON') {
          tmp.expired[1] = `${YMD(
            res.contract.contract_expired_start_date
          )} - ${YMD(res.contract.contract_expired_end_date)}`;
        }
        if (contractType === 'GRC20') {
          delete tmp.expired;
        }
        Transaction.describeTradingRecords({
          limit: 0,
          offset: 0,
          conditions: [{ address: this.$route.params.hash }],
        }).then((_) => {
          tmp.transaction_count[1] = _.total_count;
          this.info = tmp;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.chip {
  margin-left: 24px;
}
.card {
  margin-top: 16px;
  .box {
    padding: 16px;
  }
}
</style>
