var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"card",attrs:{"elevation":"2"}},[_c('v-card-title',[_c('span',[_vm._v("资产交易")]),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"TokenID / 交易哈希 / 钱包地址","append-icon":"mdi-magnify","rules":[_vm.rule]},on:{"click:append":_vm.onSearch,"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.onSearch($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"disable-sort":"","headers":_vm.table.headers,"items":_vm.table.items,"server-items-length":_vm.table.total},on:{"update:page":_vm.onPagination},scopedSlots:_vm._u([{key:"item.tx_hash",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/transactions/" + (item.tx_hash))}},[_c('Ellipsis',{attrs:{"width":_vm.width}},[_vm._v(" "+_vm._s(item.tx_hash)+" ")])],1)]}},{key:"item.from",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/address/" + (item.trading_record.from))}},[_c('Ellipsis',{attrs:{"width":_vm.width}},[_vm._v(" "+_vm._s(item.trading_record.from)+" ")])],1)]}},{key:"item.to",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/address/" + (item.trading_record.to))}},[_c('Ellipsis',{attrs:{"width":_vm.width}},[_vm._v(" "+_vm._s(item.trading_record.to)+" ")])],1)]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getTransactionName(item.trading_record))+" ")]}},{key:"item.tokenID",fn:function(ref){
var item = ref.item;
return [_c('RippleLink',{attrs:{"to":("/address/" + (_vm.$route.params.hash) + "/" + (item.details.tokenId))}},[_vm._v(" "+_vm._s(item.details ? item.details.tokenId : '-')+" ")])]}},{key:"item.pended_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.YMDHms(item.pended_at))+" ")]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }