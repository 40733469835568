<template>
  <v-card elevation="2" class="card">
    <v-card-title>
      <span>资产交易</span>
      <v-spacer />
      <v-text-field
        label="TokenID / 交易哈希 / 钱包地址"
        append-icon="mdi-magnify"
        v-model="search"
        :rules="[rule]"
        @click:append="onSearch"
        @keydown.enter="onSearch"
      />
    </v-card-title>
    <v-data-table
      disable-sort
      :headers="table.headers"
      :items="table.items"
      :server-items-length="table.total"
      @update:page="onPagination"
      class="elevation-1"
    >
      <template v-slot:[`item.tx_hash`]="{ item }">
        <RippleLink :to="`/transactions/${item.tx_hash}`">
          <Ellipsis :width="width">
            {{ item.tx_hash }}
          </Ellipsis>
        </RippleLink>
      </template>
      <template v-slot:[`item.from`]="{ item }">
        <RippleLink :to="`/address/${item.trading_record.from}`">
          <Ellipsis :width="width">
            {{ item.trading_record.from }}
          </Ellipsis>
        </RippleLink>
      </template>
      <template v-slot:[`item.to`]="{ item }">
        <RippleLink :to="`/address/${item.trading_record.to}`">
          <Ellipsis :width="width">
            {{ item.trading_record.to }}
          </Ellipsis>
        </RippleLink>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getTransactionName(item.trading_record) }}
      </template>
      <template v-slot:[`item.tokenID`]="{ item }">
        <RippleLink :to="`/address/${$route.params.hash}/${item.details.tokenId}`">
          {{
            item.details
              ? item.details.tokenId
              : '-'
          }}
        </RippleLink>
      </template>
      <template v-slot:[`item.pended_at`]="{ item }">
        {{ YMDHms(item.pended_at) }}
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import { RippleLink, Ellipsis } from '@/components';
import { Contract, Transaction } from '@/api';
import { YMDHms, YMD, valid, getTransactionName } from '@/utils';

export default {
  name: 'Transactions',
  components: { RippleLink, Ellipsis },
  data: () => ({
    width: '240px',
    search: '',
    rule: (_) =>
      !!!_ || valid.isAddress(_) || valid.isTxHash(_) || '无效的输入值',
    table: {
      headers: [
        { text: '交易哈希', value: 'tx_hash' },
        { text: '转出地址', value: 'from' },
        { text: '转入地址', value: 'to' },
        { text: '交易类型', value: 'type' },
        { text: 'TokenID', value: 'tokenID' },
        { text: '交易时间', value: 'pended_at' },
      ],
      items: [],
      total: 0,
    },
  }),
  mounted() {
    this.getTransactions(1);
  },
  methods: {
    YMDHms,
    YMD,
    getTransactionName,
    getTransactions(page) {
      const _ = this.search;
      const address = this.$route.params.hash;
      const conditions = [];

      if (valid.isNumber(_)) {
        conditions.push({
          contract_addresses: [address],
          event_details: [{ name: 'Transfer' }],
          tokenid: _
        });
      } else if (valid.isTxHash(_)) {
        conditions.push({
          contract_addresses: [address],
          event_details: [{ name: 'Transfer' }],
          tx_hash: _,
        });
      } else if (valid.isAddress(_)) {
        conditions.push({
          contract_addresses: [address],
          event_details: [{ name: 'Transfer' }],
          wallet_address: _,
        });
      } else {
        conditions.push({
          contract_addresses: [address],
          event_details: [{ name: 'Transfer' }]
      });
      }
      Transaction.describeEventDetails({
        limit: 10,
        offset: (page - 1) * 10,
        conditions,
      }).then((res) => {
        this.table = {
          ...this.table,
          items: res.event_details,
          total: res.total_count,
        };
      });
    },
    onPagination(page) {
      this.getTransactions(page);
    },
    onSearch() {
      this.getTransactions(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 16px;
}
</style>
