<template>
  <v-container>
    <v-card elevation="2">
      <v-card-title>账户地址：{{ $route.params.hash }}</v-card-title>
    </v-card>
    <v-card elevation="2" class="card">
      <div class="box">
        <v-row>
          <v-col cols="4">交易</v-col>
          <v-col cols="8">
            <RippleLink :to="`/transactions`">
              {{ count }}
            </RippleLink>
          </v-col>
        </v-row>
      </div>
    </v-card>
    <v-card elevation="2" class="card">
      <v-card-title>
        <span>资产持有</span>
        <v-spacer />
        <v-select
          clearable
          label="资产类型"
          v-model="type"
          :items="types"
          @change="onTypeChange"
        />
      </v-card-title>
      <v-data-table
        disable-sort
        :headers="table.headers"
        :items="table.items"
        :server-items-length="table.total"
        @update:page="onPagination"
        class="elevation-1"
      >
        <template v-slot:[`item.contract_address`]="{ item }">
          <RippleLink :to="`/address/${item.contract_address}`">
            {{ item.contract_address }}
          </RippleLink>
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>

<script>
import { RippleLink } from '@/components';
import { Account, Transaction } from '@/api';
import { YMDHms, YMD, isAssetContract } from '@/utils';

export default {
  name: 'Account',
  components: { RippleLink },
  data: () => ({
    count: 0,
    types: [
      { text: '卡券', value: 'COUPON' },
      { text: '积分', value: 'GRC20' },
    ],
    type: '',
    table: {
      headers: [
        { text: '资产名称', value: 'contract_name' },
        { text: '品牌名称', value: 'contract_symbol' },
        { text: '数量', value: 'balance_to_decimal' },
        { text: '合约地址', value: 'contract_address' },
      ],
      items: [],
      total: 0,
    },
  }),
  mounted() {
    this.getTransactionCount();
    this.getAssets(1);
  },
  methods: {
    YMDHms,
    YMD,
    getTransactionCount() {
      Transaction.describeTradingRecords({
        limit: 0,
        offset: 0,
        conditions: [{ address: this.$route.params.hash }],
      }).then((res) => {
        this.count = res.total_count;
      });
    },
    getAssets(page) {
      Account.describeTokenHoldingInfoByWallet(this.$route.params.hash, {
        limit: 10,
        offset: (page - 1) * 10,
        conditions: [
          { contract_types: this.type ? [this.type] : ['COUPON', 'GRC20', 'ARTWORK'] },
        ],
      }).then((res) => {
        this.table = {
          ...this.table,
          items: res.holding_infoes,
          total: res.total_count,
        };
      });
    },
    onPagination(page) {
      this.getAssets(page);
    },
    onTypeChange() {
      this.getAssets(1);
    },
  },
};
</script>

<style lang="scss" scoped>
.card {
  margin-top: 16px;
  .box {
    padding: 16px;
  }
}
</style>
